// Generated by Framer (3fa6aa4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {I0f2LBWpA: {hover: true}};

const cycleOrder = ["I0f2LBWpA"];

const serializationHash = "framer-bkXpY"

const variantClassNames = {I0f2LBWpA: "framer-v-jkj4ya"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const isSet = (value) => {
    return value !== undefined && value !== null && value !== "";
};


const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const transformTemplate1 = (_, t) => `translateX(-50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({carrousselCoverImage, carrousselLogo, height, id, link, width, ...props}) => { return {...props, EGLI9cQUs: carrousselLogo ?? props.EGLI9cQUs, V_REYOIbA: carrousselCoverImage ?? props.V_REYOIbA, Z0sNGrl13: link ?? props.Z0sNGrl13} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string;carrousselCoverImage?: {src: string; srcSet?: string};carrousselLogo?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, Z0sNGrl13, V_REYOIbA, EGLI9cQUs, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "I0f2LBWpA", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const visible = isSet(V_REYOIbA)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={Z0sNGrl13}><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-jkj4ya", className, classNames)} framer-gnsq0c`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"I0f2LBWpA"} ref={ref ?? ref1} style={{rotate: 0, ...style}} variants={{"I0f2LBWpA-hover": {rotate: 1}}} {...addPropertyOverrides({"I0f2LBWpA-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}>{visible && (<Image background={{alt: "", fit: "fill", sizes: "422px", ...toResponsiveImage(V_REYOIbA)}} className={"framer-13gltb6"} layoutDependency={layoutDependency} layoutId={"KP6Nm6KBG"} style={{borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, boxShadow: "0px 1px 2px 0px rgba(0,0,0,0.25), 0px 0px 0px 1px rgba(0, 0, 0, 0.1)"}} variants={{"I0f2LBWpA-hover": {boxShadow: "0px 2px 4px 0px rgba(0,0,0,0.25), 0px 0px 0px 1px rgba(0, 0, 0, 0.1)"}}}><motion.div className={"framer-oo18ab"} data-framer-name={"Logo Box"} layoutDependency={layoutDependency} layoutId={"u1EA84Rrx"} {...addPropertyOverrides({"I0f2LBWpA-hover": {transformTemplate: transformTemplate1}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fit", intrinsicHeight: 275.5, intrinsicWidth: 958.5, pixelHeight: 551, pixelWidth: 1917, sizes: "160px", ...toResponsiveImage(EGLI9cQUs), ...{ positionX: "left", positionY: "bottom" }}} className={"framer-1yyf68i"} data-framer-name={"sushito_logo_white"} layoutDependency={layoutDependency} layoutId={"G9HQ0Qgi1"}/></motion.div></Image>)}</motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-bkXpY.framer-gnsq0c, .framer-bkXpY .framer-gnsq0c { display: block; }", ".framer-bkXpY.framer-jkj4ya { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 40px; height: 549px; justify-content: center; min-width: 422px; padding: 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-bkXpY .framer-13gltb6 { flex: none; height: 549px; position: relative; width: 422px; }", ".framer-bkXpY .framer-oo18ab { align-content: flex-end; align-items: flex-end; bottom: 0px; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: auto; justify-content: flex-start; left: 0px; overflow: hidden; padding: 32px; position: absolute; right: 0px; z-index: 1; }", ".framer-bkXpY .framer-1yyf68i { flex: none; height: 80px; overflow: visible; position: relative; width: 160px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-bkXpY.framer-jkj4ya, .framer-bkXpY .framer-oo18ab { gap: 0px; } .framer-bkXpY.framer-jkj4ya > * { margin: 0px; margin-left: calc(40px / 2); margin-right: calc(40px / 2); } .framer-bkXpY.framer-jkj4ya > :first-child, .framer-bkXpY .framer-oo18ab > :first-child { margin-left: 0px; } .framer-bkXpY.framer-jkj4ya > :last-child, .framer-bkXpY .framer-oo18ab > :last-child { margin-right: 0px; } .framer-bkXpY .framer-oo18ab > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } }", ".framer-bkXpY.framer-v-jkj4ya.hover .framer-oo18ab { bottom: 0px; left: 50%; right: unset; width: 422px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 549
 * @framerIntrinsicWidth 422
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","fixed"]},"TKLkD0vyr":{"layout":["auto","fixed"]}}}
 * @framerVariables {"Z0sNGrl13":"link","V_REYOIbA":"carrousselCoverImage","EGLI9cQUs":"carrousselLogo"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerCFTPZEeYe: React.ComponentType<Props> = withCSS(Component, css, "framer-bkXpY") as typeof Component;
export default FramerCFTPZEeYe;

FramerCFTPZEeYe.displayName = "Customer Carroussel Card";

FramerCFTPZEeYe.defaultProps = {height: 549, width: 422};

addPropertyControls(FramerCFTPZEeYe, {Z0sNGrl13: {title: "Link", type: ControlType.Link}, V_REYOIbA: {title: "Carroussel – cover image", type: ControlType.ResponsiveImage}, EGLI9cQUs: {title: "Carroussel – logo", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerCFTPZEeYe, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})